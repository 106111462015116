<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-form-model layout="inline">
          <a-form-model-item label="文件标题">
            <a-input v-model="search.title" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="发文类型">
            <a-select v-model="search.type" placeholder="请选择" allowClear style="width: 160px">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="请示">请示</a-select-option>
              <a-select-option value="党支部请示">党支部请示</a-select-option>
              <a-select-option value="报告">报告</a-select-option>
              <a-select-option value="会议纪要">会议纪要</a-select-option>
              <a-select-option value="支部工作动态">支部工作动态</a-select-option>
              <a-select-option value="省教科院通知">省教科院通知</a-select-option>
              <a-select-option value="省规划办通知">省规划办通知</a-select-option>
              <a-select-option value="省教育学会通知">省教育学会通知</a-select-option>
              <a-select-option value="省教科院发文卡">省教科院发文卡</a-select-option>
              <a-select-option value="省规划办发文卡">省规划办发文卡</a-select-option>
              <a-select-option value="省教育学会发文卡">省教育学会发文卡</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="日期">
            <a-range-picker @change="onTimeChange" />
          </a-form-model-item>

          <a-form-model-item label="状态">
            <a-select v-model="search.status" placeholder="请选择" allowClear style="width: 120px">
              <a-select-option :value="0">待提交</a-select-option>
              <a-select-option :value="1">已提交</a-select-option>
              <a-select-option :value="2">通过</a-select-option>
              <a-select-option :value="3">不通过</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <p-button type="primary" name="搜索" @click="searchList" />
          </a-form-model-item>
          <a-form-model-item style="float: right">
            <a-button type="primary" style="margin-right: 10px" @click="gotonew">新增</a-button>
            <a-button type="default" @click="exportData">导出</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div class="tableItem">
      <a-table bordered rowKey="id" :loading="{ spinning: loading, tip: loadingTip }" :columns="tableInfo.columns"
        :data-source="tableInfo.datas" :pagination="tableInfo.pagination" @change="onChange"
        :locale="{ emptyText: '暂无数据' }" :scroll="{ y: tableInfo.tableHeight, x: 1000 }" :customRow="(record) => {
            return {
              attrs: {
                style: record.print == 1 ? 'background-color: #e6fffb' : '',
              },
            };
          }
          ">
        <span slot="index" slot-scope="text, record, index">
          <span>{{
            (tableInfo.pagination.current - 1) * tableInfo.pagination.pageSize +
            index +
            1
          }}</span>
        </span>
        <template slot="status" slot-scope="info">
          <processStatus :processModel="info" />
        </template>
        <span slot="category" slot-scope="info">
          {{ info.type.indexOf("发文卡") > -1 ? "发文卡" : "请示报告" }}
        </span>
        <template slot="print" slot-scope="text">
          <a-tag color="green" v-if="text == 1"> 已打印 </a-tag>
          <a-tag color="red" v-if="text == 0"> 未打印 </a-tag>
        </template>
        <span slot="action" slot-scope="action">
          <template v-if="action.creator == currUser">
            <p-button name="修改" @click="addOrUpdate(action)" size="small"
              v-if="action.status == 0 || action.status == 3" type="primary" />
            <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="changeStatus(action.id, 1)"
              v-if="action.status == 0 || action.status == 3">
              <a-button size="small" type="danger">提交</a-button>
            </a-popconfirm>
            <a-popconfirm title="确定要删除吗？" @confirm="deleteItem(action.id)"
              v-if="action.status == 0 || action.status == 3">
              <a-button size="small" type="danger">删除</a-button>
            </a-popconfirm>
          </template>
          <a-button @click="addOrUpdate(action)" size="small" type="primary" v-if="action.processExecuteStatus">{{
            action.taskKey.indexOf('会签') != -1 ? '签字' : '审批' }}</a-button>
          <a-button v-if="action.status == 1 || action.status == 2" @click="addOrUpdate(action, true)" size="small"
            type="default">详情</a-button>
        </span>
      </a-table>
    </div>
    <a-modal :title="editor.title" v-model="editor.visible"
      :width="editor.info.type.indexOf('发文卡') > -1 ? '90%' : '70%'" :dialog-style="{ top: '20px' }" :footer="false"
      destroyOnClose :maskClosable="false">
      <qingshi v-if="
        editor.info.type == '请示' ||
        editor.info.type == '报告' ||
        editor.info.type.indexOf('通知') > -1 ||
        editor.info.type == '党支部请示'
      " :detailMode="editor.detailMode" :info="editor.info" @callback="modelCallBack" />
      <huiyijiyao v-if="editor.info.type == '会议纪要'" :detailMode="editor.detailMode" :info="editor.info"
        @callback="modelCallBack" />
      <zhibugongzuo v-if="editor.info.type == '支部工作动态'" :detailMode="editor.detailMode" :info="editor.info"
        @callback="modelCallBack" />
      <fawen v-if="editor.info.type.indexOf('发文卡') > -1" :info="editor.info" :detailMode="editor.detailMode"
        @callback="modelCallBack" />
    </a-modal>
  </div>
</template>

<script>
  import documentdispatchApi from "@/api/work/documentdispatch";
  import qingshi from "./qingshi.vue";
  import huiyijiyao from "./huiyijiyao.vue";
  import zhibugongzuo from "./zhibugongzuo.vue";
  import fawen from "./fawen.vue";
  const columns = [
    {
      title: "序号",
      key: "index",
      align: "center",
      width: 70,
      scopedSlots: { customRender: "index" },
    },
    {
      dataIndex: "dispatchOrg",
      align: "center",
      title: "发文单位",
      width: 150,
    },
    {
      dataIndex: "title",
      align: "center",
      title: "文件标题",
      width: 280,
    },
    {
      dataIndex: "number",
      align: "center",
      title: "文号",
      width: 130,
    },
    {
      dataIndex: "type",
      align: "center",
      title: "发文类型",
      width: 100,
    },
    {
      key: "category",
      align: "center",
      title: "公文种类",
      width: 90,
      scopedSlots: { customRender: "category" },
    },
    {
      dataIndex: "creatorName",
      align: "center",
      title: "拟稿人",
      width: 90,
    },
    {
      dataIndex: "creatorOrgName",
      align: "center",
      title: "拟稿部门",
      width: 130,
    },
    {
      dataIndex: "createTime",
      align: "center",
      title: "拟稿日期",
      width: 130,
    },
    {
      key: "status",
      align: "center",
      title: "状态",
      width: 140,
      scopedSlots: { customRender: "status" },
    },
    {
      dataIndex: "print",
      align: "center",
      width: 90,
      scopedSlots: { customRender: "print" },
      title: "打印状态",
    },
    {
      title: "操作",
      key: "action",
      width: 190,
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];

  let _this;
  export default {
    name: "documentdispatch",
    data() {
      return {
        loading: false,
        loadingTip: "加载中...",
        search: {
          title: "",
          status: "",
        },

        tableInfo: {
          datas: [],
          columns,
          pagination: {
            showQuickJumper: true,
            hideOnSinglePage: false,
            showSizeChanger: true,
            total: 0,
            current: 1,
            pageSize: 20,
            showTotal: (total) => `总共${total}条`,
          },
          tableHeight: window.innerHeight - 310,
        },

        selectRows: [],
        editor: {
          title: "编辑",
          info: { type: "", id: null },
          visible: false,
          detailMode: false,
        },
        currUser: localStorage.getItem(this.$commons.User.userName),
      };
    },
    components: {
      qingshi,
      huiyijiyao,
      zhibugongzuo,
      fawen,
    },
    mounted() {
      _this = this;
      if (window.innerWidth >= 1600) {
        _this.tableInfo.pagination.pageSize = 20;
      }
      window.onresize = () => {
        _this.tableInfo.tableHeight = window.innerHeight - 330;
        if (window.innerWidth >= 1600) {
          _this.tableInfo.pagination.pageSize = 20;
        } else {
          _this.tableInfo.pagination.pageSize = 10;
        }
      };
      _this.getPageList();
    },
    methods: {
      gotonew() {
        _this.$router.push({ path: "/business/newdocument" });
      },
      changeStatus(id, status) {
        _this.loading = true;
        documentdispatchApi.changeStatus(id, status).then((res) => {
          _this.loading = false;
          if (res.errorCode === _this.$commons.RespCode.success) {
            _this.getPageList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        });
      },
      deleteItem(id) {
        _this.loading = true;
        documentdispatchApi.deleteItem(id).then((res) => {
          _this.loading = false;
          if (res.errorCode === _this.$commons.RespCode.success) {
            _this.getPageList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        });
      },
      exportData() {
        _this.loading = true;
        documentdispatchApi.exportData({ ...this.search }, (d) => {
          _this.loadingTip = "已完成" + d + "%";
          if (d == 100) {
            setTimeout(function () {
              _this.loading = false;
              _this.loadingTip = "加载中...";
              _this.$message.success("导出成功");
            }, 2000);
          }
        });
      },
      async getPageList() {
        _this.loading = true;
        let param = {
          ..._this.search,
          pageNow: _this.tableInfo.pagination.current,
          pageSize: _this.tableInfo.pagination.pageSize,
        };
        param.orgId = param.orgId ? param.orgId[param.orgId.length - 1] : null;
        documentdispatchApi.getList(param).then((res) => {
          _this.loading = false;
          if (res.errorCode === _this.$commons.RespCode.success) {
            _this.tableInfo.datas = res.data.records;
            _this.tableInfo.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
        });
      },
      onTimeChange(date, dateString) {
        _this.search.startTime = dateString[0];
        _this.search.endTime = dateString[1];
      },
      onChange(page) {
        _this.tableInfo.pagination.current = page.current;
        _this.tableInfo.pagination.pageSize = page.pageSize;
        _this.getPageList();
      },
      searchList() {
        _this.tableInfo.pagination.current = 1;
        _this.getPageList();
      },
      addOrUpdate(info, mode) {
        _this.editor.title = info.type + "编辑";
        if (info.status == 1 || info.status == 2) {
          _this.editor.title = info.type + "查看";
        }
        _this.editor.visible = true;
        _this.editor.info = info;
        _this.editor.detailMode = mode;
      },
      modelCallBack(f) {
        _this.editor.visible = false;
        _this.getPageList();
      },
    },
  };
</script>

<style lang="less" scoped></style>
